import React from "react"
import Layout from "./../components/layouts/Default"
import Seo from "./../components/layouts/Seo"

export default function Page() {
  return (
    <Layout>
      <Seo
        title="Impressum"
        description="Impressum von Florian Dierda - Finanzdienstleistungen"
        path="/impressum/"
      />
      <div className="my-16 xl:my-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max-content lg:max-w-7xl mx-auto">
          <div className="relative">
            <svg
              className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
              />
            </svg>
            <svg
              className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
              />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <section className="mb-8">
                <div className="text-gray-700 mb-6 lg:max-w-3xl lg:mb-0 mx-auto">
                  <div className="mb-8">
                    <div className="relative z-10 mb-16 md:mb-2">
                      <div className="text-lg mx-auto mb-6">
                        <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                          Rechtliches
                        </p>
                        <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                          Impressum
                        </h1>
                      </div>
                    </div>
                    <div className="prose prose-lg text-gray-700 mb-6">
                      <div>
                        <h2>Angaben gemäß § 5 TMG</h2>
                        <p>
                          Florian Dierda
                          <br />
                          Florian Dierda - Finanzdienstleistungen
                          <br />
                          Thüringerstr. 9<br />
                          34314 Espenau
                        </p>
                        <h2>Kontakt</h2>
                        <p>
                          Telefon: +49 (0) 1516 4406444
                          <br />
                          E-Mail: anfragen@dierda.de
                        </p>
                        <h2>
                          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
                        </h2>
                        <p>
                          Florian Dierda
                          <br />
                          Thüringerstr. 9<br />
                          34314 Espenau
                          {/*
                          <br />
                          <br />
                          Andreas Lautenschlager
                          <br />
                          Einbecker Str. 3<br />
                          34123 Kassel*/}
                        </p>
                        <h2>EU-Streitschlichtung</h2>
                        <p>
                          Die Europäische Kommission stellt eine Plattform zur
                          Online-Streitbeilegung (OS) bereit:{" "}
                          <a
                            href="https://ec.europa.eu/consumers/odr"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://ec.europa.eu/consumers/odr
                          </a>
                          .<br /> Unsere E-Mail-Adresse finden Sie oben im
                          Impressum.
                        </p>
                        <h2>
                          Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
                        </h2>
                        <p>
                          Wir sind nicht bereit oder verpflichtet, an
                          Streitbeilegungsverfahren vor einer
                          Verbraucherschlichtungsstelle teilzunehmen.
                        </p>
                        <h3>Haftung für Inhalte</h3>{" "}
                        <p>
                          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                          eigene Inhalte auf diesen Seiten nach den allgemeinen
                          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
                          als Diensteanbieter jedoch nicht verpflichtet,
                          übermittelte oder gespeicherte fremde Informationen zu
                          überwachen oder nach Umständen zu forschen, die auf
                          eine rechtswidrige Tätigkeit hinweisen.
                        </p>{" "}
                        <p>
                          Verpflichtungen zur Entfernung oder Sperrung der
                          Nutzung von Informationen nach den allgemeinen
                          Gesetzen bleiben hiervon unberührt. Eine
                          diesbezügliche Haftung ist jedoch erst ab dem
                          Zeitpunkt der Kenntnis einer konkreten
                          Rechtsverletzung möglich. Bei Bekanntwerden von
                          entsprechenden Rechtsverletzungen werden wir diese
                          Inhalte umgehend entfernen.
                        </p>{" "}
                        <h3>Haftung für Links</h3>{" "}
                        <p>
                          Unser Angebot enthält Links zu externen Websites
                          Dritter, auf deren Inhalte wir keinen Einfluss haben.
                          Deshalb können wir für diese fremden Inhalte auch
                          keine Gewähr übernehmen. Für die Inhalte der
                          verlinkten Seiten ist stets der jeweilige Anbieter
                          oder Betreiber der Seiten verantwortlich. Die
                          verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
                          auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                          Inhalte waren zum Zeitpunkt der Verlinkung nicht
                          erkennbar.
                        </p>{" "}
                        <p>
                          Eine permanente inhaltliche Kontrolle der verlinkten
                          Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                          Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                          Rechtsverletzungen werden wir derartige Links umgehend
                          entfernen.
                        </p>{" "}
                        <h3>Urheberrecht</h3>{" "}
                        <p>
                          Die durch die Seitenbetreiber erstellten Inhalte und
                          Werke auf diesen Seiten unterliegen dem deutschen
                          Urheberrecht. Die Vervielfältigung, Bearbeitung,
                          Verbreitung und jede Art der Verwertung außerhalb der
                          Grenzen des Urheberrechtes bedürfen der schriftlichen
                          Zustimmung des jeweiligen Autors bzw. Erstellers.
                          Downloads und Kopien dieser Seite sind nur für den
                          privaten, nicht kommerziellen Gebrauch gestattet.
                        </p>{" "}
                        <p>
                          Soweit die Inhalte auf dieser Seite nicht vom
                          Betreiber erstellt wurden, werden die Urheberrechte
                          Dritter beachtet. Insbesondere werden Inhalte Dritter
                          als solche gekennzeichnet. Sollten Sie trotzdem auf
                          eine Urheberrechtsverletzung aufmerksam werden, bitten
                          wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                          von Rechtsverletzungen werden wir derartige Inhalte
                          umgehend entfernen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
